<template>
    <div style="width: 100%; background-color: #FFFFFF;">
        <div class="pay-content">
            <div style="height: 23px;"></div>
            <div class="title">订单提交成功，请尽快完成付款</div>
            <div class="desc">请您在30分钟内完成支付，否则订单会被自动取消</div>
            <div class="cell">
                <div class="price">¥{{amount}}</div>
                <div class="text1">应付金额</div>
                <div class="cell-inner2" v-if="order_type==1 || order_type==2">
                    <img style="width: 100%;" :src="qrcode_url" />
                </div>
                <div class="text5" v-if="order_type==1">打开微信扫码支付</div>
                <div class="text5" v-if="order_type==2">打开支付宝扫码支付</div>
                <div class="cell-inner" v-if="order_type==3">
                    <div class="text2">请按照以下信息进行对公转账付款</div>
                    <div class="text3">账户名称：北京火星火翎文化科技有限公司<span style="color:#0379F8;"> 复制</span></div>
                    <div class="text3">银行账户：100010124824<span style="color:#0379F8;"> 复制</span></div>
                    <div class="text3">开户行：友利银行（中国）有限公司<span style="color:#0379F8;"> 复制</span></div>
                    <a-popconfirm trigger="click" :icon="null" @confirm="onpay">
                        <template slot="title">
                            <div class="text-center lh-1-5">
                                付款成功后请向客服提交订单号和付款凭证截图
                                <br />我们会帮助完成订单
                            </div>
                        </template>
                        <button class="button-c" >我已付款</button>
                        <!-- <a-button class="pay-btn" size="large" type="primary" :loading="loading"
                            @click="visible = true;">我已付款</a-button> -->
                    </a-popconfirm>
                    <!-- <button class="button-c" @click="onpay()">我已付款</button> -->
                    <!-- <com-to-com-pay
                        :order_id="order_id"
                        :order_type="order_type"
                        @success="onpay"
                        style="margin-top: 42px;"
                        ></com-to-com-pay> -->
                </div>
                <div class="text6" v-if="order_type==3">付款成功后请点击按钮并向客服提交付款凭证，我们将在确认到账后24小时内为您开通权益</div>
            </div>
            <div class="title2">商品信息</div>
            <div class="orderid">订单号：9cd7186d-87a8-4849-90d7-965bcff124d2</div>
            <a-row v-for="(scope, key) in dataTableList" :key="key" style="margin-top: 16px;">
                <a-col :span="24" class="list-header"
                    style="line-height:40px;background: #F4F6F6;border: 1px solid #EBF0F2;padding-left: 1%;height:40px;">
                    <a-col span="4"><span>商品类型</span></a-col>
                    <a-col span="2"><span>时长</span></a-col>
                    <a-col span="4"><span>下单时间</span></a-col>
                    <a-col span="7">
                        <span>权益说明</span>
                    </a-col>

                    <a-col span="3"><span>付款方式</span></a-col>
                    <a-col span="4"><span>价格</span></a-col>
                </a-col>
                <a-col :span="24"
                    style="height:130px;border: 1px solid #EBF0F2;display:flex;align-items:center;padding-left: 1%;background-color: #FFFFFF;">
                    <a-col :span="4">
                        <img src="@/assets/images/web/member/个人vip.png" v-if="auth_type==2" alt="个人VIP">
                        <img src="@/assets/images/web/member/企业VIP.png" v-if="auth_type==1" alt="企业VIP">
                        <img src="@/assets/images/web/member/省钱卡.png" v-if="auth_type==3" alt="省钱卡">
                    </a-col>
                    <a-col :span="2">
                        <span v-if="vip_type==1">月度</span>
                        <span v-if="vip_type==2">季度</span>
                        <span v-if="vip_type==3">年度</span>
                        <span v-if="vip_type==4">定制</span>
                    </a-col>
                    <a-col :span="4">
                        <span>{{coop_start_date}}</span>
                    </a-col>
                    <a-col :span="7" style="text-align:left;">
                        <a-col :span="24" v-if="auth_type != 3">
                            <p v-if="vip_type==4">曲库类型：全部曲库</p>
                            <p v-if="vip_type!=4">选曲范围：会员专区+音效库</p>
                            <p>下载限制：<span v-if="download_num == -1">不限量/天</span><span v-else>{{download_num}}</span></p>
                            <p v-if="auth_type==1">授权说明：企业商用</p>
                            <p v-if="auth_type==2">授权说明：个人（非商用）</p>
                        </a-col>
                        <a-col :span="24" v-else>
                            <p v-if="vip_type==1" style="width: 80%;">
                                当月立即获得零售曲库8折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用）</p>
                            <p v-else style="width: 80%;">每月获得零售曲库6折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用）</p>
                        </a-col>
                    </a-col>

                    <a-col :span="3">{{payway}}

                    </a-col>
                    <a-col :span="4">
                        <span style="color:#E30D0D;">¥{{amount}}</span>
                    </a-col>
                </a-col>
            </a-row>
            <div style="height: 57px;"></div>
        </div>

    </div>
</template>
<script>
    import { Popconfirm } from 'ant-design-vue';
    // import ComToComPay from '../pay/components/ComToComPay.vue'
    export default {
        data() {
            return {
                dataTableList: [{}],
                order_type: this.$route.params.type,
                order_id: this.$route.query.order_id,
                amount: 0,
                payway: '',
                paywayArr: ["", "微信", "支付宝", "公对公"],
                auth_type: 1,
                vip_type: 1,
                coop_start_date: '',
                download_num: -1,
                qrcode_url: '',
                vip_id: '',
                loading: false,
            }
        },
        components: {
            APopconfirm: Popconfirm,
            // ComToComPay
        },
        created: function () {
            console.log(this.order_type);
        },
        mounted() {
            if (this.order_id != null){
                this.viporderInfo();
            }else{
                var dd = new Date();
                var m = dd.getMonth()+1;
                m = m<10?"0"+m:m;
                var d = dd.getDate();
                d = d<10?"0"+d:d;
                var today = dd.getFullYear()+"-"+m+"-"+d;

                this.vip_id = this.$route.query.vip_id;
                this.$axios.VIPLIST().then(res => {
                    if (res.data.code == 0) {
                        var list = res.data.data.list;
                        for (var key in list){
                            for (var item of list[key]){
                                if(item.vip_id == this.vip_id){
                                    this.amount = item.price;
                                    this.payway = this.paywayArr[3];
                                    this.vip_type = item.vip_type;
                                    this.auth_type = item.auth_type;
                                    this.download_num = item.download_num;
                                    this.coop_start_date = today;
                                }
                            }
                        }
                    }
                })
            }
        },
        computed: {

        },
        methods: {
            viporderInfo() {
                let params = {
                    'order_id': this.order_id,
                    // 'user_id': this.$store.state.userInfo.user_id,
                };
                this.$axios.viporderInfo(params).then(res => {
                    // console.log(res.data);
                    if (res.data.code == 0) {
                        // console.log(res.data);
                        this.vip_id = res.data.data.vip_id;
                        this.amount = res.data.data.total_amount;
                        this.payway = this.paywayArr[parseInt(res.data.data.pay_way)];
                        this.vip_type = res.data.data.vip_info[0].vip_type;
                        this.auth_type = res.data.data.vip_info[0].auth_type;
                        this.download_num = res.data.data.vip_info[0].download_num;
                        this.coop_start_date = res.data.data.vip_info[0].coop_start_date;
                        if (res.data.data.pay_way == 1 || res.data.data.pay_way == 2) {
                            this.qrcode();
                        }
                    }
                })
            },
            qrcode() {
                let params = {
                    'order_id': this.order_id,
                    'pay_way': this.order_type,
                };
                let that = this;
                this.$axios.qrCodeV2(params).then(res => {
                    if (res.data.code == 0) {
                        let data = res.data.data;
                        that.qrcode_url = data.pay_data;
                        that.timer = setInterval(() => {
                            that.checkPayStatus({ 'order_id': this.order_id });
                        }, 4000);
                    }
                })
            },
            checkPayStatus(params) {
                this.$axios.PayCheckOrder(params).then(res => {
                    if (res.data.code == 0) {
                        let pay_status = res.data.data ? res.data.data.pay_status : '';
                        if (pay_status == '1') {
                            // if (pay_status == '0') {
                            clearInterval(this.timer);
                            this.$message.success("您的订单已支付成功。");
                            this.$router.push({ path: '/payresult/' + this.order_id });
                        }
                    }
                })
            },
            onpay() {
                let params = {
                    'vip_id': this.vip_id,
                    'pay_way': this.order_type,
                };
                this.$axios.viporgPay(params).then(res => {
                    if (res.data.code == 0) {
                        //let data = res.data.data;
                        //let order_id = data.order_id;
                        let url = `/member_v2?menukey=4`;
                        this.$router.replace(url);
                    }
                })
                // this.$router.push({path:'/member_v2?menukey=4'});
            }
        },
    }
</script>
<style lang="scss" scoped>
    .pay-content {
        width: 80%;
        margin: 0 auto;

        .title {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            /* margin-top:23px; */
        }

        .desc {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            margin-top: 18px;
        }

        .cell {
            width: 100%;
            height: 400px;
            border: solid 1px #E6E6E6;
            margin-top: 28px;
        }

        .cell-inner {
            width: 54%;
            height: 200px;
            background: #F4F6F6;
            margin: 0 auto;
            margin-top: 24px;
        }

        .cell-inner2 {
            width: 180px;
            height: 180px;
            background-color: #EFF2F6;
            margin: 0 auto;
            margin-top: 24px;
        }

        .button-c {
            width: 16%;
            margin-left: 42%;
            height: 40px;
            background: #FF872A;
            border-radius: 7px;
            border: none;
            color: white;
            /* margin:0 auto; */
            margin-top: 14px;
        }

        .price {
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #EF3131;
            margin-top: 44px;
        }

        .text1 {
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            /* margin-top:12px; */
        }

        .text2 {
            width: 50%;
            margin-left: 25%;
            font-size: 14px;
            font-weight: 400;
            color: rgba(102, 102, 102, 0.85);
            padding-top: 24px;
        }

        .text3 {
            width: 50%;
            margin-left: 25%;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            padding-top: 4px;
        }

        .text5 {
            width: 100%;
            text-align: center;

            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            margin-top: 24px;
        }

        .text6 {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);

            width: 54%;
            margin: 0 auto;
            margin-top: 14px;
        }

        .title2 {
            margin-top: 24px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
        }

        .orderid {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
        }
    }
</style>